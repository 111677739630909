import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import TeamsBioLanding from '../components/TeamsBioLanding';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

export default function TeamsBioLandingPage({location, pageContext, data }) {

  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulTeamsBioLandingPage?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <TeamsBioLanding
          data={data?.contentfulTeamsBioLandingPage}
          leftDonutBackgroundImage={data?.contentfulTeamsBioLandingPage?.leftDonutBackgroundImage}
          rightDonutBackgroundImage={data?.contentfulTeamsBioLandingPage?.rightDonutBackgroundImage}
          basePath={pageContext.basePath}
          capsulesBackgroundImage={data?.contentfulTeamsBioLandingPage?.capsulesLeftBackgroundImage}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query TeamsBioTemplate($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
    }
    contentfulTeamsBioLandingPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      contentful_id
      entryTitle
      slug
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      leftDonutBackgroundImage {
        file {
          fileName
          url
        }
        description
      }
      rightDonutBackgroundImage {
        file {
          fileName
          url
        }
        description
      }
      dotMatrixBackgroundImage {
        file {
          url
          contentType
        }
        description
      }
      capsulesLeftBackgroundImage {
        file {
          fileName
          url
        }
        description
      }
      heroImage {
        file {
          fileName
          url
        }
        description
      }
      headerSectionTitle
      headerSectionDescription {
        raw
      }
      ourLeadersTitle
      ourLeadersShortDescription {
        raw
      }
      ourLeadersSection {
        ...compPerson
      }
      ourBusinessTeamTitle
      ourBusinessTeamShortDescription {
        raw
      }
      capsulesBackgroundImage {
        file {
          fileName
          url
        }
        description
      }
      ourBusinessTeamSection {
        ...compPerson
      }
      ourThoughtLeadersTitle
      ourThoughtLeadersShortDescription {
        raw
      }
      ourThoughtLeadersSection {
        ...compPerson
      }
      awardsSection {
        title
        description
        file {
          fileName
          url
        }
        description
      }
      letsTalkBackgroundImage {
        file {
          fileName
          url
        }
        description
      }
      letsTalkDescription {
        raw
      }
      letsTalkCtaButtonLink {
        label
        link {
          ... on ContentfulInternalLink {
            slug
          }
          ... on ContentfulWebLink {
            url
          }
        }
      }
    }
  }
  fragment compPerson on ContentfulComponentPerson {
    entryTitle
    name
    image {
      title
      description
      file {
        url
      }
    }
    personalQuote {
      personalQuote
    }
    bioWriteup {
      raw
    }
    linkedInImage {
      file {
        url
      }
      description
    }
    emailId
    linkedinId
    dotMatrixBackgroundImage {
      file {
        url
      }
    }
    rightDonutBackgroundImage {
      file {
        url
      }
    }
    closeIcon {
      file {
        url
      }
    }
    designation
    region{
      raw
    }
    sys {
      contentType {
        sys {
          id
          type
        }
      }
    }
  }
`;
