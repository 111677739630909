import * as React from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import TeamsBioOurBusinessTiles from '../../TeamsBioOurBusinessTiles';
import './TeamsBioOurThought.scss';
import '../../../scss/common.scss';

const OurThougthLeaders = ({ leadersTitle, leadersDescription, leadersData, backgroundImg }) => {
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <div className="our-thougths-cls">
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" alignItems="flex-start">
                <Grid item container xl={6} lg={6} md={8}>
                  <h2 className="teambio-title" locId="ourThoughtLeadersTitle">
                    {leadersTitle}
                  </h2>
                  <p className="teambio-info" locId="ourThoughtLeadersShortDescription">
                    {documentToReactComponents(JSON.parse(leadersDescription?.raw))}
                  </p>
                </Grid>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <TeamsBioOurBusinessTiles data={leadersData} teamLocId='ourThoughtLeadersSection' backgroundImg={backgroundImg} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default OurThougthLeaders;
