import React from 'react';
import TeamsBioHeader from './TeamsBioHeader';
import TeamsBioOurBusiness from './TeamsBioOurBusiness';
import TeamsBioOurLeaders from './TeamsBioOurLeaders';
import TeamsBioOurThought from './TeamsBioOurThought';
import AwardsSlider from '../AwardsSlider';
import LetsTalk from '../LetsTalk';
import './TeamsBioLanding.scss';
import { myStyles } from './styles';
import clsx from 'clsx';
import { Container } from '@material-ui/core';

const TeamsBioLanding = ({
  data,
  leftDonutBackgroundImage,
  rightDonutBackgroundImage,
  capsulesBackgroundImage,
  basePath,
  backgroundImg,
  autoRotateEnabled
}) => {
  const imgData = { leftDonutBackgroundImage, rightDonutBackgroundImage };
  const classes = myStyles(imgData);

  return (
    <div className="team-bio-main">
      <TeamsBioHeader
        headerSectionTitle={data?.headerSectionTitle}
        headerSectionDescription={data?.headerSectionDescription?.raw}
        heroImage={data?.heroImage?.file?.url+'?fm=webp&q=100'}
        heroImageDescription={data?.heroImage?.description}
        capsulesBackgroundImage={data?.capsulesBackgroundImage}
        dotMatrixBackgroundImage={data?.dotMatrixBackgroundImage}
      />
      <div className={clsx('bg-donutsL', classes['bg-donutsL'])}>
        <TeamsBioOurLeaders
          title={data?.ourLeadersTitle}
          shortDescription={data?.ourLeadersShortDescription?.raw}
          ourLeadersSection={data?.ourLeadersSection}
          dotMatrixBackgroundImage={data.dotMatrixBackgroundImage}
          backgroundImg={backgroundImg}
        />
      
      <TeamsBioOurBusiness
        items={data?.ourBusinessTeamSection}
        title={data?.ourBusinessTeamTitle}
        shortDescription={data?.ourBusinessTeamShortDescription}
        capsulesImage={capsulesBackgroundImage}
        backgroundImg={backgroundImg}
      />
      </div>
      <div className={clsx('bg-donutsR', classes['bg-donutsR'])}>
        <TeamsBioOurThought
          leadersTitle={data?.ourThoughtLeadersTitle}
          leadersDescription={data?.ourThoughtLeadersShortDescription}
          leadersData={data?.ourThoughtLeadersSection}
          backgroundImg={backgroundImg}
        />
      </div>
      <Container maxWidth="xl" className="awards-slider-teambio">
        <AwardsSlider awardsSection={data.awardsSection} backgroundImg={backgroundImg} autoRotateEnabled={autoRotateEnabled}/>
      </Container>
      <LetsTalk
        title={data?.letsTalkDescription}
        image={data?.letsTalkBackgroundImage}
        LetsTalkButton={data?.letsTalkCtaButtonLink}
        basePath={basePath}
      />
    </div>
  );
};

export default TeamsBioLanding;
