import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({

  'bg-dotMatrix': (dotMatrixBackgroundImage) => ({
    '&::after': {
      background: `url(${dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    }
    
  }),
}))