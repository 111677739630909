import React from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './TeamsBioOurBusiness.scss';
import TeamsBioOurBusinessTiles from '../../TeamsBioOurBusinessTiles';
import { myStyles } from './style';
import clsx from 'clsx';

const TeamsBioOurBusiness = ({ items, title, shortDescription, capsulesImage,backgroundImg }) => {
  const classes = myStyles(capsulesImage);

  return (
    <>
      <div className={clsx('bios-business-presenter-cls', classes['bg-capsules'])}>
        <Container maxWidth="xl" className='zero-padding-div'>
          <div className='business-container'>
            <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={6} lg={6} md={8}>
                  <h2 className='teambio-title' locId="ourBusinessTeamTitle">
                    {title}
                  </h2>
                  <p className='teambio-info' locId="ourBusinessTeamShortDescription">
                    {documentToReactComponents(JSON.parse(shortDescription?.raw))}
                  </p>
                </Grid>
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} >
                  <TeamsBioOurBusinessTiles data={items} teamLocId='ourBusinessTeamSection' backgroundImg={backgroundImg}/>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>

  )
}

export default TeamsBioOurBusiness;