import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../../scss/common.scss';
import BioPopUp from '../BioPopUp';

export default function TeamsBioOurBusinessTiles({ data, teamLocId, backgroundImg }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const handleClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  const handleEnterKeyPress = (index, e) => {
    if (e.charCode == 13) {
      setActiveIndex(index);
      setOpen(true);
    }
  };
  const numberOfProfiles = process.env.GATSBY_NUMBER_OF_BUSINESS_LEADERS_IN_TEAMS_BIO;
  let leadersList = [...data].splice(0, numberOfProfiles);
  return (
    <>
      {leadersList.map((person, index) => (
        <>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
              <Card
                className="team-titles"
                onClick={() => {
                  handleClick(index);
                }}
                onKeyPress={(e) => {
                  handleEnterKeyPress(index, e);
                }}
                tabIndex="0"
              >
                <img
                  src={person?.image?.file.url+'?fm=webp&q=100'}
                  alt={person?.image?.description}
                  locId={`${teamLocId}image`}
                />
                 <CardContent className="descp-box">
                  <Typography gutterBottom variant="body2" component="p" className='profilename' locId={`${teamLocId}name`}>
                    {person?.name}
                  </Typography>
                  <Typography variant="body2" component="p" className='profile-details' locId={`${teamLocId}designation`}>
                    {person?.designation}
                  </Typography>
                  <Typography variant="body2" component="p" className='profile-details' locId={`${teamLocId}region`}>
                    {person?.region && documentToReactComponents(JSON.parse(person?.region?.raw))}
                  </Typography>
                </CardContent>
              </Card>
          </Grid>
          {index == activeIndex ? <BioPopUp data={person} open={open} setOpen={setOpen} backgroundImg={backgroundImg}/> : <></>}
          {(index === 1 || index === 13) && (
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
              &nbsp;
            </Grid>
          )}
          {(index === 2 || index === 8) && (
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
              &nbsp;
            </Grid>
          )}
          {(index === 6 || index === 17) && (
            <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
              &nbsp;
            </Grid>
          )}
        </>
      ))}
    </>
  );
}
