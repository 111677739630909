import * as React from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import TeamBioOurLeadersTiles from '../../TeamsBioOurLeadersTiles';
import './TeamsBioOurLeaders.scss';
import '../../../scss/common.scss';
import { myStyles } from './style';
import clsx from 'clsx';

export default function TeamsBioOurLeaders({
  title,
  shortDescription,
  ourLeadersSection,
  dotMatrixBackgroundImage,
  backgroundImg
}) {
  const classes = myStyles(dotMatrixBackgroundImage);

  return (
    <>
    <Container maxWidth="xl" className='zero-padding-div'>
      <div className={clsx('our-leaders', classes['bg-dotMatrix'])}>
          <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid item xl={6} lg={6} md={8}>
                <h2 className="teambio-title" locId="ourLeaderTeamTitle">
                  {title}
                </h2>
                <p className="teambio-info" locId="ourLeaderTeamShortDescription">
                  {documentToReactComponents(JSON.parse(shortDescription))}
                </p>
              </Grid>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <TeamBioOurLeadersTiles
                  ourLeadersSection={ourLeadersSection}
                  teamLocId="ourLeaderTeamSection"
                  backgroundImg={backgroundImg}
                />
              </Grid>
            </Grid>
          </Grid>
      </div>
      </Container>
    </>
  );
}
