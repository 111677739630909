import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../TeamsBioLanding/TeamsBioOurLeaders/TeamsBioOurLeaders.scss';
import { myStyles } from '../TeamsBioLanding/TeamsBioOurLeaders/style';
import BioPopUp from '../BioPopUp';
import clsx from 'clsx';

export default function TeamBioOurLeadersTiles({ ourLeadersSection, teamLocId, backgroundImg }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const handleClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  const handleEnterKeyPress = (index, e) => {
    if (e.charCode == 13) {
      setActiveIndex(index);
      setOpen(true);
    }
  };
  const numberOfProfiles = process.env.GATSBY_NUMBER_OF_LEADERS_PROFILE_IN_TEAMS_BIO;
  let authorList = [...ourLeadersSection].splice(0, numberOfProfiles);
  const classes = myStyles(ourLeadersSection);
  return (
    <>
      {authorList.map((person, index) => {
        return (
          <>
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={6}
              xs={6}
              className={clsx('bg-dotMatrix', classes['bg-dotMatrix'])}
            >
              <Card
                className="team-titles"
                onClick={() => {
                  handleClick(index);
                }}
                onKeyPress={(e) => {
                  handleEnterKeyPress(index, e);
                }}
                tabIndex="0"
              >
                <CardMedia
              component="img"
              alt={person?.image?.description}
              height="240"
              image={person?.image?.file?.url+'?fm=webp&q=100'} 
              locId={`${teamLocId}image`}/>
                <CardContent className="descp-box">
                  <Typography gutterBottom variant="body2" component="p" className='profilename' locId={`${teamLocId}name`}>
                    {person?.name}
                  </Typography>
                  <Typography variant="body2" component="p" className='profile-details' locId={`${teamLocId}designation`}>
                    {person?.designation}
                  </Typography>
                  <p className='profile-details'>
                    {person?.region && documentToReactComponents(JSON.parse(person?.region?.raw))}
                  </p>
                </CardContent>
              </Card>
            </Grid>
            {index == activeIndex ? (
              <BioPopUp data={person} open={open} setOpen={setOpen} backgroundImg={backgroundImg}/>
            ) : (
              <></>
            )}
            {(index === 1 || index === 3 || index === 5 ) && (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                &nbsp;
              </Grid>
            )}
            {index === 6 && (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                &nbsp;
              </Grid>
            )}
            {(index === 8) && (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                &nbsp;
              </Grid>
            )}
            {index === 10 && (
              <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                &nbsp;
              </Grid>
            )}
          </>
        );
      })}
    </>
  );
}
