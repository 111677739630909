import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'team-bio-header': (data) => ({
    '&::after': {
      background: `url(${data?.capsulesBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
  'header-container': (data) => ({
    '&::after': {
      background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    },
  }),
}));
