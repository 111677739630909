import React from 'react';
import Grid from '@material-ui/core/Grid';
import './TeamsBioHeader.scss';
import Container from '@material-ui/core/Container';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { myStyles } from './styles';
import clsx from 'clsx';

const TeamsBioHeader = ({
  headerSectionTitle,
  headerSectionDescription,
  heroImage,
  heroImageDescription,
  capsulesBackgroundImage,
  dotMatrixBackgroundImage,
}) => {
  const data = { capsulesBackgroundImage, dotMatrixBackgroundImage };
  const classes = myStyles(data);
  return (
    <>
      <div className={clsx('team-bio-header', classes['team-bio-header'])}>
        <Container maxWidth="xl" className="teamsbio-container">
          <Grid container className={clsx('header-container', classes['header-container'])}>
            <Grid item xs={6} sm={6} md={5} lg={5} xl={5}>
              <Grid className="header-left-text">
                <Grid>
                  <h1 className="title" locId="headerSectionTitle">
                    {headerSectionTitle}
                  </h1>
                  <p locId="headerSectionDescription">
                    {headerSectionDescription &&
                      documentToReactComponents(JSON.parse(headerSectionDescription))}
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
              <div className="header-image">
                <img src={heroImage+'?fm=webp&q=100'} alt={heroImageDescription} width="100%" locId="heroImage" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default TeamsBioHeader;
